<template>
  <div class="extranet-block">

    <v-form class="extranet-block__form" @submit.prevent="submitHandler">

      <v-row class="row-logo-switch" align="center">
        <v-col class="d-flex align-center">
          <img
              class="row-logo"
              alt=""
              src="/src/assets/images/creation-logo.svg"
          />
        </v-col>
      </v-row>

      <v-row class="row-select-profile select-profile-title" justify="center">
        Veuillez sélectionner votre type de profil :
      </v-row>

      <v-row class="row-btn-toggle" justify="center">
        <v-btn-toggle v-model="useAzure" mandatory>
          <v-btn :value="false" class="toggle-button_client">Client</v-btn>
          <v-btn :value="true" class="toggle-button_collaborateur">Collaborateur</v-btn>
        </v-btn-toggle>
      </v-row>
      <v-container>
        <v-row class="row-select-connexion" justify="center">
          <div class="select-profile-title">CONNEXION</div>
        </v-row>
        <v-row class="row-connection-type" justify="center">
          <h3 class="connection-type-title">{{ useAzure ? 'Collaborateur' : 'Client' }}</h3>
        </v-row>

        <v-row class="row-switch" justify="center"></v-row>
        <v-row v-if="useAzure" class="row-azure-connect azure-connect">
          <a class="azure-connect__link" href="#">
            <span class="azure-connect__icon">
              <img alt="Azure icon" src="/src/assets/images/azure.svg"/>
            </span>
            <span class="azure-connect__text" @click="SignIn()"
            >Connectez-vous avec Azure AD</span
            >
          </a>
        </v-row>
        <v-row v-if="useAzure" class="row-separator separator">
          <span class="separator__text">OU</span>
        </v-row>
        <v-row class="row-input">
          <v-text-field
              v-model="email"
              :rules="rules"
              class="input-field"
              color="red"
              hide-details="auto"
              label="Email"
              required
          />
        </v-row>
        <v-row class="row-input">
          <v-text-field
              v-model="password"
              :rules="rules"
              :type="showPassword ? 'text' : 'password'"
              class="input-field"
              color="red"
              hide-details="auto"
              label="Mot de passe"
              required
          >
            <template v-slot:append-inner>
              <v-icon class="eyeIcon" @click="showPassword = !showPassword">
                {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
              </v-icon>
            </template>
          </v-text-field>
        </v-row>
        <v-row class="row-info_pass">
          <a class="forgot-password-link" :href="getForgotPasswordUrl()">Mot de passe oublié ?</a>
        </v-row>
        <v-row class="row-button" justify="center">
          <v-btn :loading="loading" class="button" large type="submit">
            S'identifier
          </v-btn>
        </v-row>

        <!-- <v-row class="row-info">
          <p class="help">
            Vous n'avez pas de compte ? Contactez votre gestionnaire pour y
            accéder
          </p>
        </v-row> -->
      </v-container>
    </v-form>
  </div>
</template>

<script>
import {accountAzureService as accountAzureAPI} from '../../services/account_azure.service';
import {accountService as accountAPI} from '../../services/account.service';
import {GRC_BASE_URL} from '../../config/config';

const accountAzureService = accountAzureAPI();
const accountService = accountAPI();

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      showPassword: false,
      useAzure: false,
      labelTrue: 'Collaborateur',
      labelFalse: 'Client',
      rules: [
        (value) => {
          if (value) return true;
          return 'Veuillez remplir ce champ !';
        },
      ],
    };
  },
  methods: {
    getForgotPasswordUrl() {
      return `${GRC_BASE_URL}authentication/forgot_password`;
    },
    submitHandler() {
      if (!this.useAzure && this.email !== '' && this.password !== '') {
        this.loading = true;
        return accountService
        .login(this.email, this.password)
        .then(() => {
          this.loading = false;
          this.handleRedirect();
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
      } else if (this.useAzure) {
        this.SignIn();
      }
    },
    async SignIn() {
      try {
        await accountAzureService.login().then(() => this.handleRedirect());
      } catch (error) {
        console.error("Login failed", error);
      }
    },
    handleRedirect() {
      this.$nextTick(() => {
        const redirectUrl = sessionStorage.getItem('redirectAfterLogin') ?? "/projets";
        sessionStorage.removeItem('redirectAfterLogin')
        window.location.href = redirectUrl;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.row-input,
.row-button,
.row-separator,
.row-azure-connect {
  margin-bottom: 30px;
}

.row-logo-switch {
  left: 30px !important;
  justify-content: center; /* Centers horizontally */
  position: relative !important;
  flex-wrap: nowrap !important;
  display: flex;
  align-items: center !important;
}

.row-btn-toggle {
  display: flex;
  justify-content: center;

  .v-btn {
    background-color: #f5f5f5 !important;
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    transition: background-color 0.3s, color 0.3s !important;

    &:hover {
      background-color: #000000 !important;
      color: #fff !important;
    }

    &.v-btn--active {
      background-color: #c90c0f !important;
      color: #fff !important;

      &:hover {
        background-color: #272727 !important;
        color: #fff !important;
      }
    }
  }

}

.input-field {
  label[class],
  input[type] {
    font-weight: 500;
  }

  .v-input__slot[class][class][class] {
    &::before {
      border-color: var(--v-stratis-grey-base);
    }
  }
}

.extranet-block {
  @include size(100%);
  background: center / cover no-repeat url('/assets/images/login-background.png');

  &::before {
    @include size(auto, 100%);
    @include absolute(50%, 10%, null, 52%);
    background: center / contain no-repeat url('/assets/images/login-people.svg');
    content: '';
    transform: translateY(-50%);

    @include breakpoint(medium down) {
      content: none;
    }
  }

  &__form {
    @include absolute(50%, null, null, 14%);
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 10px 20px rgba(#000, 16%);
    min-height: 384px;
    margin: 0;
    max-width: 480px;
    padding: 70px 80px 55px;
    transform: translateY(-50%);
    width: 100%;

    @include breakpoint(medium down) {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include breakpoint(small down) {
      max-width: 450px;
    }

    @include breakpoint(xsmall down) {
      max-width: 300px;
      padding: 60px 40px 40px;
    }
  }

  .row {
    &.row-logo {
      margin-bottom: 0;
    }

    &.row-separator {
      margin-top: 24px;
    }

    &.row-input {
      margin-top: 0;
      width: 100%;

      + .row-input {
        margin-top: 30px;
      }

      > div {
        width: 100%;
      }
    }

    &.row-button {
      margin-top: 50px;

      .v-size--large {
        min-width: 220px;
        text-transform: uppercase;
      }
    }

    &.row-info {
      margin-top: 35px !important;
    }

  }

  .separator {
    align-items: center;
    display: flex;
    position: relative;

    &::before,
    &::after {
      background-color: rgba(#727272, 0.3);
      content: '';
      flex-grow: 1;
      height: 1px;
      width: 1px;
    }

    &__text {
      color: #494949;
      display: block;
      font-size: 14px;
      font-weight: 400;
      margin: 0 5px;
      text-transform: uppercase;
    }
  }

  $azure-color: #03a9f4;

  .azure-connect {
    $this: &;

    &__link {
      @include trs;
      border-radius: 5px;
      display: flex;
      text-decoration: none;
      width: 100%;

      @include on-event {
        background-color: rgba($azure-color, 20%);
      }
    }

    &__icon {
      align-items: center;
      background-color: $azure-color;
      border: 1px solid $azure-color;
      border-radius: 5px 0 0 5px;
      display: flex;
      flex-shrink: 0;
      padding: 10px;

      img {
        display: block;
        margin: 0;
      }
    }

    &__text {
      align-items: center;
      border: 1px solid $azure-color;
      border-radius: 0 3px 3px 0;
      color: #272727;
      display: flex;
      flex-grow: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
      margin-left: -1px;
      overflow: hidden;
      padding: 12px 35px;
      position: relative;

      @include breakpoint(small down) {
        padding: 12px 20px;
      }
    }
  }

  .help {
    color: #272727;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
  }
}

.button {
  &.v-btn {
    background-color: #c90c0f !important;
    border-color: #c90c0f !important;
    color: #fff;
    font-weight: 400;
    height: 55px;
    min-width: 220px;
    padding: 0 20px;
    text-transform: uppercase;
  }

  .v-btn__content {
    .v-icon {
      &.v-icon--left,
      &.v-icon__right {
        @include size(14px);
        font-size: 14px;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  &:not(.v-btn--outlined).stratis-grey {
    color: white;
  }

  &:not(.v-btn--round) {
    &.v-size--default {
      padding: 0 25px;

      &.v-btn--square {
        padding: 0 16px;
        min-width: 41px;

        .v-icon {
          font-size: 14px;
        }
      }
    }

    &.v-size--large {
      padding: 0 20px;
      min-width: 55px;

      &.v-btn--square {
        padding: 0 20px;

        .v-icon {
          font-size: 14px;
        }
      }
    }

    &.v-size--small {
      padding: 0 12px;
      min-width: 44px;

      .v-icon {
        font-size: 14px;
      }
    }
  }
}

.switsh-role__container {
  left: 14%;
  margin: 0;
  max-width: 480px;
  position: relative;
  top: 13%;
  transform: translateY(-13%);
  width: 100%;

  @include breakpoint(medium down) {
    left: 50%;
    top: 2%;
    transform: translate(-50%, 2%);
  }

  @include breakpoint(small down) {
    .row-logo-switch {
      flex-direction: column;
      align-items: center;
      justify-content: center; /* Centers horizontally */

      .v-col {
        width: unset;
      }
    }
  }
}
</style>

<style>
.row-info_pass {
  margin-top: 40px !important;
  text-align: right !important;

  .forgot-password-link {
    margin: 10px 0;
    margin-left: auto !important;
    color: #0a0909 !important;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.extranet-block__form {
  max-width: 500px !important;
}

.v-btn-group--density-default.v-btn-group {
  height: 40px !important;
}

.v-selection-control .v-label {
  white-space: nowrap !important;
  word-break: break-word;
}

.row-select-profile {
  font-style: normal !important;
  color: #c90c0f;
  font-size: 19px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 17px;
}

.row-select-connexion {
  text-decoration: none !important;
  padding: 26px 15px;
  text-align: center;
  font: normal normal normal 26px/34px Roboto;
  letter-spacing: 0;
  color: #494949;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}

.row-connection-type {
  color: #c90c0f;
  font-size: 12px !important;
}

</style>
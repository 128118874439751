// stores/useTaskStore.js
import {defineStore} from 'pinia';

export const useTaskStore = defineStore('taskStore', {
  state: () => ({
    selectedTask: sessionStorage.getItem("selectedTask") ? JSON.parse(sessionStorage.getItem("selectedTask")) : null,
    taskCommentsStatus: sessionStorage.getItem("taskCommentsStatus") ? JSON.parse(sessionStorage.getItem("taskCommentsStatus")) : {} // Stores the commentsExist status for each task by ID

  }),
  getters: {
    commentsExistForSelectedTask(state) {
      return state.selectedTask ? state.taskCommentsStatus[state.selectedTask] || false : false;
    }
  },
  actions: {
    setSelectedTask(task) {
      this.selectedTask = task;
    },
    clearSelectedTask() {
      this.selectedTask = null;
    },
    displayAlertGraphicDesignerButton(taskId) {
      if (taskId) {
        this.taskCommentsStatus[taskId] = true;
      }
    },
    removeComment(taskId) {
      if (taskId) {
        this.selectedTask = null;
        delete this.taskCommentsStatus[taskId];
      }
    },
  }
});
import {useUserStore} from '../store/userStore';

let userStore;

export function setupInactivityListener() {
  if (!userStore) {
    userStore = useUserStore();
  }

  function resetInactivity() {
    userStore.resetInactivityTimer();
  }

  document.addEventListener('mousemove', resetInactivity);
  document.addEventListener('keydown', resetInactivity);
  document.addEventListener('mousedown', resetInactivity);
  document.addEventListener('scroll', resetInactivity);
  document.addEventListener('touchstart', resetInactivity);

  return () => {
    document.removeEventListener('mousemove', resetInactivity);
    document.removeEventListener('keydown', resetInactivity);
    document.removeEventListener('mousedown', resetInactivity);
    document.removeEventListener('scroll', resetInactivity);
    document.removeEventListener('touchstart', resetInactivity);
  };
}

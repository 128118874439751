import {myMSALObj} from "./../config/msalConfig";
import {useUserStore} from "../store/userStore";
import axios from "axios";

const baseURL = import.meta.env.VITE_BASE_URL;

export function accountAzureService() {
  const login = async () => {
    try {
      // Check if MSAL is initialized before using it
      if (!myMSALObj) {
        throw new Error(
          "MSAL not initialized. Call initializeMsal() before using MSAL API."
        );
      }

      // Handle redirect promise asynchronously
      await myMSALObj.handleRedirectPromise();

      // Check if user signed in
      const account = myMSALObj.getActiveAccount();

      if (!account) {
        // Redirect anonymous user to login page
        const response = await myMSALObj.loginPopup({
          redirectUri: baseURL
        });
        const idToken = response.idToken;
        return loginAzure(idToken)
        .then(() => {
        })
        .catch((err) => {
          console.log(err);
          //this.loading = false;
        });
      }


    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const logoutAzure = async () => {
    const userStore = useUserStore();

    try {
      if (!myMSALObj) {
        throw new Error(
          "MSAL not initialized. Call initializeMsal() before using MSAL API."
        );
      }
      await myMSALObj.handleRedirectPromise();
      await myMSALObj.logoutRedirect();
      userStore.logout();
    } catch (error) {
      console.error("Logout error:", error);
    }
  };


  async function loginAzure(id_token) {
    try {
      const userStore = useUserStore();
      const response = await axios.post(
        "login_azure",
        {id_token}
      );
      const {token, refresh_token, username, avatar, email, id, admin} = response.data; // Assuming response data is JSON
      let user = {Username: username, email: email, avatar: avatar, id, admin}
      userStore.setToken(token);
      userStore.setRefreshToken(refresh_token)
      userStore.setUser(user)
      sessionStorage.setItem("isAzure", true);

    } catch (error) {
      // Handle login error
      console.error("Login failed:", error);
    }
  }

  return {login, logoutAzure};
}

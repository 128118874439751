import {defineStore} from 'pinia';
import router from '../router/index.js';

const CLIENT_TOKEN_EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 4 hours in milliseconds
const CLIENT_INACTIVITY_TIME_LIMIT = 24 * 60 * 60 * 1000; // 4 hours in milliseconds
const DEFAULT_TOKEN_EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const DEFAULT_INACTIVITY_TIME_LIMIT = 24 * 60 * 1000; //

export const useUserStore = defineStore('userStore', {
  state: () => {
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    const token = sessionStorage.getItem('token') ? JSON.parse(sessionStorage.getItem('token')) : null;
    const refresh_token = sessionStorage.getItem('refresh_token') ? JSON.parse(sessionStorage.getItem('refresh_token')) : null;
    const tokenExpiry = sessionStorage.getItem('tokenExpiry') ? JSON.parse(sessionStorage.getItem('tokenExpiry')) : null;

    return {user, token, refresh_token, tokenExpiry, inactivityTimeout: null};
  },
  getters: {
    isAuthenticated: (state) => {
      return !!state.token && !!state.refresh_token && !isTokenExpired(state.tokenExpiry);
    },
    isClient: (state) => {
      return state.user?.is_client ?? false;
    },
  },
  actions: {
    setToken(token) {
      const expirationTime = this.isClient ? CLIENT_TOKEN_EXPIRATION_TIME : DEFAULT_TOKEN_EXPIRATION_TIME;
      const expiryTimestamp = Date.now() + expirationTime;
      this.token = token;
      this.tokenExpiry = expiryTimestamp;
      sessionStorage.setItem('token', JSON.stringify(token));
      sessionStorage.setItem('tokenExpiry', JSON.stringify(expiryTimestamp));
      this.resetInactivityTimer();
    },
    setRefreshToken(refresh_token) {
      this.refresh_token = refresh_token;
      sessionStorage.setItem('refresh_token', JSON.stringify(refresh_token));
      this.resetInactivityTimer();
    },
    getRefreshToken() {
      return sessionStorage.getItem('refresh_token');
    },
    setUser(user) {
      this.user = user;
      sessionStorage.setItem('user', JSON.stringify(user));
      this.resetInactivityTimer();
    },
    logout() {
      try {
        this.token = null;
        this.refresh_token = null;
        this.user = null;
        this.tokenExpiry = null;
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refresh_token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('tokenExpiry');
        this.clearInactivityTimer();
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },
    checkTokenExpiry() {
      if (this.tokenExpiry && Date.now() > this.tokenExpiry) {
        this.logout();
      }
    },
    startInactivityTimer() {
      const inactivityTimeLimit = this.isClient ? CLIENT_INACTIVITY_TIME_LIMIT : DEFAULT_INACTIVITY_TIME_LIMIT;
      this.clearInactivityTimer();
      this.inactivityTimeout = setTimeout(() => {
        this.logout();
        router.push({
          name: 'login',
        });
      }, inactivityTimeLimit);
    },
    resetInactivityTimer() {
      this.startInactivityTimer();
    },
    clearInactivityTimer() {
      if (this.inactivityTimeout) {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = null;
      }
    },
  },
});

// Store the last close time in sessionStorage when the tab is closed
window.addEventListener('beforeunload', () => {
  sessionStorage.setItem('lastCloseTime', JSON.stringify(Date.now()));
});

function isTokenExpired(expiryTimestamp) {
  return expiryTimestamp ? Date.now() > expiryTimestamp : false;
}

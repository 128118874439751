const formats = {
  tablet: 'Tablette',
  mobile: 'Mobile',
  desktop: 'Ecran Large',
  laptop: 'Laptop',
  other: 'Autre',
}
export default {

  translateFormat(view) {
    return formats[view] || 'Aucune idée';
  }
}